/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
//import post1 from "assets/images/examples/testimonial-6-2.jpg";
import post2 from "assets/y-game.mp4";
import post3 from "assets/images/tetris.png";
import post4 from "assets/images/code.png";
import post5 from "assets/c-pr.mp4";
//import ReactPlayer from "react-player";

function Places() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
            Check my projects
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={6}>
            <TransparentBlogCard
              image={post5}
              title="C project. Test grade/management system."
              description="Using text files as storage devices I made a system where there are admin, professors and students."
              action={{
                route: "https://github.com/orest132/c-program",
                color: "info",
                label: "GitHub",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <TransparentBlogCard
              image={post2}
              title="Yahtzee game"
              description="Yahtzee with Java and MySql"
              action={{
                route: "https://github.com/orest132/yahtzee",
                color: "info",
                label: "GitHub",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <BackgroundBlogCard
              image={post3}
              title="Tetris"
              description="Enjoy playing tetris"
              action={{
                type: "internal",
                route: "/tetris",
                label: "Play",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <BackgroundBlogCard
              image={post4}
              title="Live coding"
              description="In the command line type run and the code will execute.Type clear to clear console. Only python is currently set up."
              action={{
                type: "internal",
                route: "/code",
                label: "Code",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
