import './Tetris.scss'
import * as BABYLON from "@babylonjs/core"
import * as GUI from "@babylonjs/gui"
import {useEffect} from "react";
import {OimoJSPlugin} from "@babylonjs/core";
import routes from "../../routes";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
const OIMO = require('oimo')
function Tetris(){

    useEffect(()=>{
        game();
    },[]);

    function game(){
        var canvas = document.getElementById("canvas");
        console.log(canvas)
        // canvas.height="100%";
        var engine = new BABYLON.Engine(canvas, true);
        console.log(engine)
        var v3 = BABYLON.Vector3;
        var current_mesh_being_delt; var tetris_objects = []; var mesh_group=[]; var block_size = 8.0;  var done_rotating_on_one_side=false; var group_nr;
        let drop_height = 12 * block_size;
        var score = 0; var stop_accelarating=false;var windowW, windowH;
        var text1;
        var field =[];
        var button_size=60;

        //declare and fill 3d array with 0 to act as a container tetris objects
        let t;
        let t1;
        for (var o = 0; o < 12; o++) {
            t = [];
            field.push(t);
            for (var p = 0; p < 7; p++) {
                t1 = []
                field[o].push(t1);
                for (var q = 0; q < 7; q++) {
                    field[o][p].push(0);
                }
            }
        }




        var createScene = function () {
            var scene = new BABYLON.Scene(engine);

            var camera = new BABYLON.ArcRotateCamera("Camera", 1.52, 1, 250, new BABYLON.Vector3(0, 20, 0), scene);

            camera.attachControl(canvas);
            camera.maxZ = 5000;
            camera.lowerAlphaLimit = 0.1;
            camera.upperAlphaLimit=3;
            camera.lowerBetaLimit =0.75;
            camera.upperBetaLimit =1.8;

            new BABYLON.HemisphericLight("hemi", new BABYLON.Vector3(0, 1, 0), scene);

            var advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");

            text1 = new GUI.TextBlock();
            text1.textVerticalAlignment=0;
            text1.textHorizontalAlignment=1;
            text1.paddingRight = 30;
            text1.paddingTop = 15;
            text1.text = 0;
            text1.color = "white";
            text1.fontSize = 100;
            advancedTexture.addControl(text1);

            var stackPanel = new GUI.StackPanel();
            stackPanel.isVertical = false;
            stackPanel.height = button_size+20+"px";
            //stackPanel.verticalAlignment = BABYLON.GUI.Control.VERTICAL_ALIGNMENT_BOTTOM;
            stackPanel.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;

            var stackOutside = new GUI.StackPanel();
            //stackOutside.isVertical = false;
            stackOutside.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_BOTTOM;
            //stackOutside.horizontalAlignment = BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
            stackOutside.addControl(stackPanel)

            advancedTexture.addControl(stackOutside); var is_one_invalid=false;

            var button = GUI.Button.CreateImageOnlyButton("but", "{% static 'main/images/left.png' %}");
            button.paddingBottomInPixels =
                Number(button.width = button_size + "px");
            button.height = button_size+"px";
            button.color = "transparent"
            button.onPointerClickObservable.add(function() {
                for(let i=0;i<4;i++){
                    if(mesh_group[i].position.x+block_size>26)
                        is_one_invalid=true;
                }
                if(!is_one_invalid){
                    for(let i=0;i<4;i++){
                        mesh_group[i].position.x+=block_size;
                    }
                }

                is_one_invalid=false;
            });
            stackPanel.addControl(button);

            var button2 = GUI.Button.CreateImageOnlyButton("but", "{% static 'main/images/down.png' %}");
            button2.paddingBottomInPixels =
                Number(button2.width = button_size + "px");
            button2.height = button_size+"px";
            button2.color = "transparent"
            button2.onPointerClickObservable.add(function() {
                for(let i=0;i<4;i++){
                    if((mesh_group[i].position.z+block_size)>26){
                        is_one_invalid=true;
                    }

                }

                if(!is_one_invalid){
                    for(let i=0;i<4;i++){
                        mesh_group[i].position.z+=(block_size);
                    }
                }

                is_one_invalid=false;
            });
            stackPanel.addControl(button2);

            var button3 = GUI.Button.CreateImageOnlyButton("but", "{% static 'main/images/right.png' %}");

            button3.width = button_size+"px";
            button3.height = button_size+"px";
            button3.color = "transparent"
            button3.onPointerClickObservable.add(function() {
                for(let i=0;i<4;i++){
                    if(mesh_group[i].position.x-block_size<-26)
                        is_one_invalid=true;
                }
                if(!is_one_invalid){
                    for(let i=0;i<4;i++){
                        mesh_group[i].position.x-=block_size;
                    }
                }

                is_one_invalid=false;
            });
            stackPanel.addControl(button3);

            var button4 = GUI.Button.CreateImageOnlyButton("but", "{% static 'main/images/up.png' %}");
            button4.width = button_size+"px";
            button4.height = button_size+"px";
            button4.color = "transparent";
            button4.verticalAlignment=1;
            button4.horizontalAlignment=1;
            button4.top=-button_size-10;
            button4.left=-button_size;
            button4.onPointerClickObservable.add(function() {
                console.log("done")
                for(let i=0;i<4;i++){
                    if(mesh_group[i].position.z-block_size<-26)
                        is_one_invalid=true;
                }
                if(!is_one_invalid){
                    for(let i=0;i<4;i++){
                        mesh_group[i].position.z-=block_size;
                    }
                }

                is_one_invalid=false;
            });
            advancedTexture.addControl(button4);

            var button5 = GUI.Button.CreateImageOnlyButton("but", "{% static 'main/images/y.png' %}");
            button5.paddingBottomInPixels =
                button5.verticalAlignment=1;
            button5.horizontalAlignment=0;
            button5.width = button_size+"px";
            button5.height = button_size+"px";
            button5.color = "transparent"
            button5.onPointerUpObservable.add(function() {
                rotate_right(group_nr);
            });
            advancedTexture.addControl(button5);

            var button6 = GUI.Button.CreateImageOnlyButton("but", "{% static 'main/images/z.png' %}");
            button6.paddingBottomInPixels =
                button6.verticalAlignment=1;
            button6.horizontalAlignment=0;
            button6.width = button_size+"px";
            button6.height = button_size+"px";
            button6.left=button_size;
            button6.color = "transparent"
            button6.onPointerUpObservable.add(function() {
                rotate_right_z(group_nr);
            });
            advancedTexture.addControl(button6);

            var button7 = GUI.Button.CreateImageOnlyButton("but", "{% static 'main/images/p.png' %}");
            button7.verticalAlignment=1;
            button7.horizontalAlignment=0;
            button7.left=button_size*2;
            button7.width = button_size+"px";
            button7.height = button_size+"px";
            button7.color = "transparent"
            button7.onPointerUpObservable.add(function() {
                if(!stop_accelarating){
                    for(let i=0;i<4;i++){
                        if(mesh_group[i].position.y<=-9)
                            stop_accelarating=true;
                        mesh_group[i].position.y-=1;
                    }
                }
            });
            advancedTexture.addControl(button7);





            var mat = new BABYLON.StandardMaterial("ground", scene);
            mat.specularColor = BABYLON.Color3.Black();

            var g = BABYLON.Mesh.CreateBox("ground", 58, scene);

            g.position.y = -20;
            g.position.x = 0
            g.scaling.y = 0.01;
            g.material = mat;

            scene.enablePhysics(new BABYLON.Vector3(0, -10, 0), new BABYLON.OimoJSPlugin(undefined, 0,OIMO));
            tetris_objects.push(g);

            if(current_mesh_being_delt===undefined || current_mesh_being_delt.position.y==-10){
                mesh_group=create_group(Math.floor(randomNumber(1,7)));
            }


            g.physicsImpostor = new BABYLON.PhysicsImpostor(g, BABYLON.PhysicsImpostor.BoxImpostor, {
                mass: 0,
                restitution: 0.9
            }, scene);

            canvas.onresize = function() {
                engine.resize();
                console.log("resizing");
            };
            window.onresize = function() {
                engine.resize();
                console.log("resizing");
            };


            return scene;
        };

        //tetris objects will be boxes and groups are made of boxes that move together
        function create_group(nr){

            var mesh_group=new Array(4);
            for(let i=0;i<4;i++){
                mesh_group[i] = BABYLON.Mesh.CreateBox("box", block_size, scene);
            }

            mesh_group[0].position = new BABYLON.Vector3(0, drop_height, 0);
            mesh_group[1].position = new BABYLON.Vector3(0, drop_height-block_size, 0);

            if(nr==1){
                mesh_group[1].position = new BABYLON.Vector3(-block_size, drop_height, 0);
                mesh_group[2].position = new BABYLON.Vector3(block_size, drop_height, 0);
                mesh_group[3].position = new BABYLON.Vector3(2*block_size, drop_height, 0);
            }
            else if(nr==2){
                mesh_group[2].position = new BABYLON.Vector3(block_size, drop_height-block_size, 0);
                mesh_group[3].position = new BABYLON.Vector3(2*block_size, drop_height-block_size, 0);
            }
            else if(nr==3){
                mesh_group[2].position = new BABYLON.Vector3(-block_size, drop_height-block_size, 0);
                mesh_group[3].position = new BABYLON.Vector3(-(2*block_size), drop_height-block_size, 0);
            }
            else if(nr==4){
                mesh_group[2].position = new BABYLON.Vector3(-block_size, drop_height, 0);
                mesh_group[3].position = new BABYLON.Vector3(-block_size, drop_height-block_size, 0);
            }
            else if(nr==5){
                mesh_group[2].position = new BABYLON.Vector3(-block_size, drop_height, 0);
                mesh_group[3].position = new BABYLON.Vector3(block_size, drop_height-block_size, 0);
            }
            else if(nr==6){
                mesh_group[2].position = new BABYLON.Vector3(block_size, drop_height-block_size, 0);
                mesh_group[3].position = new BABYLON.Vector3(-block_size, drop_height-block_size, 0);
            }
            else{
                mesh_group[2].position = new BABYLON.Vector3(block_size, drop_height, 0);
                mesh_group[3].position = new BABYLON.Vector3(-block_size, drop_height-block_size, 0);
            }

            group_nr = nr;
            return mesh_group;
        }

        function rotate_helper(pivot_index){
            var pivot = mesh_group[pivot_index];
            for(let i=0;i<4;i++){
                if(pivot_index!=i){
                    if(pivot.position.x==mesh_group[i].position.x){
                        var offset=pivot.position.y - (mesh_group[i].position.y);
                        mesh_group[i].position.x=pivot.position.x-offset;
                        mesh_group[i].position.y=pivot.position.y;
                    }
                    else if(pivot.position.y==mesh_group[i].position.y){
                        var offset=pivot.position.x - (mesh_group[i].position.x);
                        mesh_group[i].position.x=pivot.position.x;
                        mesh_group[i].position.y=pivot.position.y+offset;
                    }
                    else{
                        var offset=pivot.position.x - (mesh_group[i].position.x);
                        mesh_group[i].position.x=mesh_group[i].position.x+(2*offset);
                    }
                }
            }
        }

        function rotate_helper_z(pivot_index){
            var pivot = mesh_group[pivot_index];
            for(let i=0;i<4;i++){
                if(pivot_index!=i){
                    if(pivot.position.z==mesh_group[i].position.z){
                        var offset=pivot.position.y - (mesh_group[i].position.y);
                        mesh_group[i].position.z=pivot.position.z-offset;
                        mesh_group[i].position.y=pivot.position.y;
                    }
                    else if(pivot.position.y==mesh_group[i].position.y){
                        var offset=pivot.position.z - (mesh_group[i].position.z);
                        mesh_group[i].position.z=pivot.position.z;
                        mesh_group[i].position.y=pivot.position.y+offset;
                    }
                    else{
                        var offset=pivot.position.z - (mesh_group[i].position.z);
                        mesh_group[i].position.z=mesh_group[i].position.z+(2*offset);
                    }
                }
            }
        }

        function rotate_right(nr){
            if(nr==1){
                var pivot_index=1;
                rotate_helper(pivot_index);
            }
            else if(nr==2){
                var pivot_index=1;
                rotate_helper(pivot_index);
            }
            else if(nr==3){
                var pivot_index=1;
                rotate_helper(pivot_index);
            }
            else if(nr==4){
            }
            else if(nr==5){
                var pivot_index=0;
                rotate_helper(pivot_index);
            }
            else if(nr==6){
                var pivot_index=1;
                rotate_helper(pivot_index);
            }
            else{
                var pivot_index=0;
                rotate_helper(pivot_index);
            }

        }

        function rotate_right_z(nr){
            if(nr==1){
                var pivot_index=1;
                rotate_helper_z(pivot_index);
            }
            else if(nr==2){
                var pivot_index=1;
                rotate_helper_z(pivot_index);
            }
            else if(nr==3){
                var pivot_index=1;
                rotate_helper_z(pivot_index);
            }
            else if(nr==4){
            }
            else if(nr==5){
                var pivot_index=0;
                rotate_helper_z(pivot_index);
            }
            else if(nr==6){
                var pivot_index=1;
                rotate_helper_z(pivot_index);
            }
            else{
                var pivot_index=0;
                rotate_helper_z(pivot_index);
            }

        }

        var bool=false;
        function update_group(){
            for(let i=0;i<4;i++){
                mesh_group[i].position.y-=0.1;
            }

            var br = false;
            let temp_bool;
            let index_row_el;
            for (let i = 0; i < 4; i++) {
                var k = 0;
                current_mesh_being_delt = mesh_group[i];
                for (var key in tetris_objects) {
                    var object = tetris_objects[key];
                    if (current_mesh_being_delt.intersectsMesh(object, true)) {

                        //when collide once, make box size smaller to avoid edges and move on
                        if (bool === true) {
                            for (let j = 0; j < 4; j++) {
                                var temp = mesh_group[j];
                                mesh_group[j] = BABYLON.Mesh.CreateBox("box", block_size - 0.01, scene);
                                mesh_group[j].position = new BABYLON.Vector3(temp.position.x, temp.position.y, temp.position.z);
                                temp.dispose();
                            }
                            br = true;
                            bool = false;
                            break
                        }
                        //when collide twice, return size and fill 3d array with the index in tetris_objects.
                        for (let j = 0; j < 4; j++) {
                            var temp = mesh_group[j];
                            mesh_group[j] = BABYLON.Mesh.CreateBox("box", block_size, scene);
                            mesh_group[j].position = new BABYLON.Vector3(temp.position.x, temp.position.y, temp.position.z);
                            temp.dispose();
                        }
                        for (let s = 0; s < 4; s++) {
                            mesh_group[s].position.y += 0.1;

                            mesh_group[s].position.y = Math.round(mesh_group[s].position.y);
                            var index = tetris_objects.length;
                            var y = Math.round((mesh_group[s].position.y + 16) / 8);
                            var x = Math.round((mesh_group[s].position.x + 24) / 8);
                            var z = Math.round((mesh_group[s].position.z + 24) / 8);

                            field[y][x][z] = index;

                            tetris_objects.push(mesh_group[s]);
                        }
                        score += 1;
                        text1.text = score;
                        stop_accelarating = false;

                        //this removes the row that gets completed
                        for (var o = 0; o < 12; o++) {
                            temp_bool = true;
                            for (var p = 0; p < 7; p++) {
                                for (var q = 0; q < 7; q++) {
                                    if (field[o][p][q] == 0)
                                        temp_bool = false;
                                }
                            }
                            if (temp_bool == true) {
                                index_row_el = o;
                                for (let o = index_row_el; o < 12; o++) {
                                    if (index_row_el == o) {
                                        for (var p = 0; p < 7; p++) {
                                            for (var q = 0; q < 7; q++) {
                                                tetris_objects[field[o][p][q]].position.y = -5000;
                                            }
                                        }
                                        score += 10;
                                        text1.text = score;
                                    }
                                    if (o == 11) {
                                        for (var p = 0; p < 7; p++) {
                                            for (var q = 0; q < 7; q++) {
                                                field[o][p][q] = 0;
                                            }
                                        }
                                    } else {
                                        for (var p = 0; p < 7; p++) {
                                            for (var q = 0; q < 7; q++) {
                                                field[o][p][q] = field[o + 1][p][q];
                                                tetris_objects[field[o][p][q]].position.y -= block_size;
                                            }
                                        }
                                    }

                                }
                            }
                        }
                        var rand = randomNumber(1, 7);
                        mesh_group = create_group(Math.floor(rand));
                        br = true;
                        bool = true;
                        break

                    }
                }

                if (br == true)
                    break
            }
        }

        var randomNumber = function (min, max) {
            if (min == max) {
                return (min);
            }
            var random = Math.random();
            return ((random * (max - min)) + min);
        };


        function move(key){
            var is_one_invalid=false;
            if(key=="s"){
                for(let i=0;i<4;i++){
                    if((mesh_group[i].position.z+block_size)>26){
                        is_one_invalid=true;
                    }

                }

                if(!is_one_invalid){
                    for(let i=0;i<4;i++){
                        mesh_group[i].position.z+=(block_size);
                    }
                }

                is_one_invalid=false;
            }
            else if(key=="w"){
                for(let i=0;i<4;i++){
                    if(mesh_group[i].position.z-block_size<-26)
                        is_one_invalid=true;
                }
                if(!is_one_invalid){
                    for(let i=0;i<4;i++){
                        mesh_group[i].position.z-=block_size;
                    }
                }

                is_one_invalid=false;
            }
            else if(key=="a"){
                for(let i=0;i<4;i++){
                    if(mesh_group[i].position.x+block_size>26)
                        is_one_invalid=true;
                }
                if(!is_one_invalid){
                    for(let i=0;i<4;i++){
                        mesh_group[i].position.x+=block_size;
                    }
                }

                is_one_invalid=false;
            }
            else if(key=="d"){
                for(let i=0;i<4;i++){
                    if(mesh_group[i].position.x-block_size<-26)
                        is_one_invalid=true;
                }
                if(!is_one_invalid){
                    for(let i=0;i<4;i++){
                        mesh_group[i].position.x-=block_size;
                    }
                }

                is_one_invalid=false;

            }
        }

        function rotate_r(key){
            if(key=="c"){
                rotate_right(group_nr);
            }
        }
        function rotate_r_z(key){
            if(key=="v"){
                rotate_right_z(group_nr);
            }
        }

        var scene = createScene();

        scene.onKeyboardObservable.add((kbInfo) => {
            switch (kbInfo.type) {
                case BABYLON.KeyboardEventTypes.KEYDOWN:
                    if(kbInfo.event.key=="p"){
                        if(!stop_accelarating){
                            for(let i=0;i<4;i++){
                                if(mesh_group[i].position.y<=-9)
                                    stop_accelarating=true;
                                mesh_group[i].position.y-=1;
                            }
                        }


                    }
                    break;
                case BABYLON.KeyboardEventTypes.KEYUP:
                    rotate_r(kbInfo.event.key);
                    rotate_r_z(kbInfo.event.key);
                    move(kbInfo.event.key);
                    break;
            }
        });

        engine.runRenderLoop(function() {
            update_group();
            scene.render();
        });
    }

    return (
        <div id={'tetrisContainer'}>
            <DefaultNavbar
                routes={routes}
                action={{
                    type: "external",
                    route: "",
                    label: "To do",
                    color: "info",
                }}
                transparent
                light
            />
            <canvas id={'canvas'}></canvas>
        </div>
    );
}

export default Tetris;