import React, {useEffect} from 'react';
import './App.scss';
import Home from "./mycomponents/Home/Home";
import Coding from "./mycomponents/Coding/Coding";
import {createBrowserRouter, Routes, Route, RouterProvider, useLocation, Navigate} from "react-router-dom";
import Tetris from "./mycomponents/Tetris/Tetris";
import Author from "./mycomponents/Author";
import {CssBaseline, ThemeProvider} from "@mui/material";
import routes from "./routes";
import theme from "assets/theme";

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element:  <Home />,
//   },
//   {
//     path: "/code",
//     element: <Coding/>
//   },
//   {
//     path: "/tetris",
//     element: <Tetris />
//   },
//   {
//     path: "/test",
//     element: <Author />
//   }
// ]);

export default function App() {
    const { pathname } = useLocation();

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.key} />;
            }

            return null;
        });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
                {getRoutes(routes)}
            </Routes>
        </ThemeProvider>
    );
}