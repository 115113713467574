import React, { useCallback, useState } from 'react';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import cv from 'assets/OrestCV.pdf';

import './Sample.css';

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

const resizeObserverOptions = {};

const maxWidth = 800;

export default function Sample() {
    const [file, setFile] = useState(cv);
    const [numPages, setNumPages] = useState();
    const [containerRef, setContainerRef] = useState(null);
    const [containerWidth, setContainerWidth] = useState();

    const onResize = useCallback((entries) => {
        const [entry] = entries;

        if (entry) {
            setContainerWidth(entry.contentRect.width);
        }
    }, []);

    useResizeObserver(containerRef, resizeObserverOptions, onResize);

    function onFileChange(event) {
        const pdfUrl = cv;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "OrestKollcakuCV.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    }

    return (
        <div height="100%">
            <div className="Example">
                <div className="Example__container">
                    <div className="Example__container__load">
                        <button onClick={onFileChange} type="button" >Download</button>
                    </div>
                    <div className="Example__container__document" ref={setContainerRef}>
                        <div style={{margin: '0 auto 0 auto'}} width="80%">
                            <iframe src={file} width="100%" height="750px" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}