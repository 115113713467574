import './Coding.scss'
import Terminal, {ColorMode, TerminalOutput} from "react-terminal-ui";
import React, {useState, useRef, useEffect} from "react";
import {Editor} from "@monaco-editor/react";
import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {httpSubmitFileContent, httpSubmitInput} from "../../requests";
import {socket} from "../../socket";
import routes from "routes"
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import MKBox from "../../components/MKBox";
import rgba from "assets/theme/functions/rgba";

function Coding(){
    const editorRef = useRef(null);
    function handleEditorDidMount(editor, monaco) {
        editorRef.current = editor;
    }

    const [isConnected, setIsConnected] = useState(socket.connected);
    const [fooEvents, setFooEvents] = useState([]);

    useEffect(() => {
        function onConnect() {
            setIsConnected(true);
            console.log('Connected as '+socket.id);
        }

        function onDisconnect() {
            setIsConnected(false);
        }

        function onFooEvent(value) {
            setFooEvents(previous => [...previous, value]);
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('foo', onFooEvent);

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('foo', onFooEvent);
        };
    }, []);

    socket.on('output', (output)=>outputToTerminal(output.output, true))

    const [terminalLineData, setTerminalLineData] = useState([]);
    const [language, setLanguage] = useState('py');

    function outputToTerminal(input, fromSocket){
        if (input==='run'||input==='run ')
            return
        if (input.includes("run")){
            run(input.substring(4));
        }
        else if (input==='clear')
            setTerminalLineData([])
        else if(fromSocket){
            const result = input.split(/\r?\n/);
            setTerminalLineData([...terminalLineData, ...result.map(e=><TerminalOutput key={terminalLineData.length+e}>{e}</TerminalOutput>)]);
        }
        else{
            setTerminalLineData([...terminalLineData, <TerminalOutput key={terminalLineData.length}>{input}</TerminalOutput>]);
            httpSubmitInput(input).then(r => {
                console.log("input was posted?", r)});
        }

    }

    const handleChange = (event) => {
        setLanguage(event.target.value);
    };

    function run(filename) {
        const fileData = {filename: filename, language: getExt(language), data: editorRef.current.getValue()}
        httpSubmitFileContent(fileData, socket.id).then(r => {
            console.log("posted", language)});
    }

    function getExt(language){
        if (language==='python')
            return 'py';
        else
            return language;
    }

    return(
        <div style={{backgroundColor: rgba('#2f303a', 0.89)}}>
            <DefaultNavbar
                routes={routes}
                action={{
                    type: "external",
                    route: "",
                    label: "To do",
                    color: "info",
                }}
                relative={true}
                transparent
                light
            />
            <div style={{padding: '0px 10px 0 10px'}}>
                <Editor theme="vs-dark" height="65vh" language={language} value="print('Hello, World!')" onMount={handleEditorDidMount}/>
                <Terminal height="30vh" colorMode={ ColorMode.Dark }  onInput={ terminalInput => outputToTerminal(terminalInput, false) }>
                    { terminalLineData }
                </Terminal>
                <MKBox
                    width={'100px'}
                    sx={'float: right'}
                    mt={-95}
                    transparent
                    light
                >
                    <FormControl fullWidth minHeight={20}>
                        <InputLabel id="demo-simple-select-label">Language</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={language}
                            label="Language"
                            onChange={handleChange}
                        >
                            <MenuItem value={'python'}>Python</MenuItem>
                            <MenuItem value={'java'}>Java</MenuItem>
                            <MenuItem value={'c'}>C</MenuItem>
                        </Select>
                    </FormControl>
                </MKBox>
            </div>
        </div>
    );
}

export default Coding;