const API_URL = process.env.NODE_ENV === 'production' ? 'https://orestkollcaku.com:8000/v1' : 'http://localhost:8080/v1';

// Load planets and return as JSON.

// Submit given launch data to launch system.
async function httpSubmitFileContent(content, id) {
    try {
        return await fetch(`${API_URL}/fileContent`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({"content": content.data, filename: content.filename,language: content.language, "id": id}),
        });
    } catch(err) {
        return {
            ok: false,
        };
    }
}

async function httpSubmitInput(input) {
    try {
        return await fetch(`${API_URL}/inputs`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({"input": input}),
        });
    } catch(err) {
        return {
            ok: false,
        };
    }
}


export {
    httpSubmitFileContent,
    httpSubmitInput
};